<template>
  <div :class="['tech', $store.state.lang !== 'zh' ? 'tech_en' : '']">
    <!-- p1-->
    <section class="tech-section-1">
      <img class="sec-1-img-1" src="../../assets/images/words@2x.png" alt="">
      <div class="sec-1-title animate__animated animate__fadeInDown">{{ $t('tech.msg_1') }}</div>
      <div class="sec-1-desc">
        <p><span class="sec-1-desc-icon">&lt;</span>{{ $t('tech.msg_2') }}</p>
      </div>
    </section>
    <!-- p2-->
    <section id="tech-section-2" class="tech-section-2">
      <!-- item 1 -->
      <div id="sec-2-item-1" class="sec-2-item animate__animated monkey-hide">
        <div class="sec-2-item-img">
          <img src="../../assets/images/tech-pic_01.png" alt="">
        </div>
        <div class="sec-2-item-title">
           <p><span class="sec-2-item-title-icon">/</span>{{ $t('tech.msg_3') }}</p>
        </div>
        <div class="sec-2-item-desc">
          <p>{{ $t('tech.msg_4') }}</p>
        </div>
      </div>
      <!-- item 2 -->
      <div id="sec-2-item-2" class="sec-2-item animate__animated monkey-hide">
        <div class="sec-2-item-img">
          <img src="../../assets/images/tech-pic_02.png" alt="">
        </div>
        <div class="sec-2-item-title">
           <p><span class="sec-2-item-title-icon">/</span>{{ $t('tech.msg_5') }}</p>
        </div>
        <div class="sec-2-item-desc">
          <p>{{ $t('tech.msg_6') }}</p>
        </div>
      </div>
      <!-- item 3 -->
      <div id="sec-2-item-3" class="sec-2-item animate__animated monkey-hide">
        <div class="sec-2-item-img">
          <img src="../../assets/images/tech-pic_03.png" alt="">
        </div>
        <div class="sec-2-item-title">
           <p><span class="sec-2-item-title-icon">/</span>{{ $t('tech.msg_7') }}</p>
        </div>
        <div class="sec-2-item-desc">
          <p>{{ $t('tech.msg_8') }}</p>
        </div>
      </div>
      <!-- item 4 -->
      <div id="sec-2-item-4" class="sec-2-item animate__animated monkey-hide">
        <div class="sec-2-item-img">
          <img src="../../assets/images/tech-pic_04.png" alt="">
        </div>
        <div class="sec-2-item-title">
           <p><span class="sec-2-item-title-icon">/</span>{{ $t('tech.msg_9') }}</p>
        </div>
        <div class="sec-2-item-desc">
          <p>{{ $t('tech.msg_10') }}</p>
        </div>
      </div>
      <!-- item 5 -->
      <div id="sec-2-item-5" class="sec-2-item animate__animated monkey-hide">
        <div class="sec-2-item-img">
          <img src="../../assets/images/tech-pic_05.png" alt="">
        </div>
        <div class="sec-2-item-title">
          <p><span class="sec-2-item-title-icon">/</span>{{ $t('tech.msg_11') }}</p>
        </div>
        <div class="sec-2-item-desc">
          <p></p>
        </div>
      </div>
    </section>
    <!-- p3-->
    <section id="tech-section-3" class="tech-section-3 animate__animated monkey-hide">
      <div class="sec-3-title">{{ $t('tech.msg_12') }}</div>
      <div class="sec-3-desc">
        <p><span class="sec-3-desc-icon">&lt;</span>{{ $t('tech.msg_13') }}</p>
      </div>
      <div class="sec-3-words sec-3-words-1">
        <div class="sec-3-words-title">
          <p><span class="sec-3-words-title-icon">//</span>{{ $t('tech.msg_14') }}</p>
        </div>
        <div class="sec-3-words-desc">{{ $t('tech.msg_15') }}</div>
        <div class="sec-3-words-desc">{{ $t('tech.msg_16') }}</div>
      </div>
      <div class="sec-3-words sec-3-words-2">
        <div class="sec-3-words-title">
          <p><span class="sec-3-words-title-icon">//</span>{{ $t('tech.msg_17') }}</p>
        </div>
        <div class="sec-3-words-desc">{{ $t('tech.msg_18') }}</div>
        <div class="sec-3-words-desc">{{ $t('tech.msg_19') }}</div>
      </div>
      <div class="sec-3-img">
        <img src="../../assets/images/tech-p2_pic.png" alt="">
      </div>
    </section>

    <!-- p4-->
    <section id="tech-section-4" class="tech-section-4 animate__animated monkey-hide">
      <div class="sec-4-title">{{ $t('tech.msg_20') }}</div>
      <div class="sec-4-desc">
        <p><span class="sec-4-desc-icon">&lt;</span>{{ $t('tech.msg_21') }}</p>
      </div>
      <div class="sec-4-img">
        <img src="../../assets/images/tech-p3_pic.png" alt="">
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data () {
    return {
      p2Action: false,
      p3Action: false,
      p4Action: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.viewScroll, true)
  },
  methods: {
    viewScroll () {
      // p2动画效果
      const screenHeight = window.screen.height
      const screenWidth = window.screen.width
      const isSmall = screenWidth < 800

      const watchDom1 = document.getElementById('tech-section-2')
      const height1 = watchDom1.getBoundingClientRect().top

      if (height1 < screenHeight / 2 && !this.p2Action) {
        const dom1 = document.getElementById('sec-2-item-1')
        const dom2 = document.getElementById('sec-2-item-2')
        const dom3 = document.getElementById('sec-2-item-3')
        const dom4 = document.getElementById('sec-2-item-4')
        const dom5 = document.getElementById('sec-2-item-5')
        if (isSmall) {
          dom1.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom2.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom3.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom4.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom5.classList.replace('monkey-hide', 'animate__fadeInDown')
        } else {
          dom1.classList.replace('monkey-hide', 'animate__backInRight')
          dom2.classList.replace('monkey-hide', 'animate__backInRight')
          dom3.classList.replace('monkey-hide', 'animate__backInRight')
          dom4.classList.replace('monkey-hide', 'animate__backInRight')
          dom5.classList.replace('monkey-hide', 'animate__backInRight')
        }
        this.p2Action = true
      }
      // p3动画效果
      const watchDom3 = document.getElementById('tech-section-3')
      const height3 = watchDom3.getBoundingClientRect().top
      if (height3 < screenHeight / 2 && !this.p3Action) {
        const dom1 = document.getElementById('tech-section-3')
        if (isSmall) {
          dom1.classList.replace('monkey-hide', 'animate__fadeInDown')
        } else {
          dom1.classList.replace('monkey-hide', 'animate__backInRight')
        }
        this.p3Action = true
      }
      // p4动画效果
      const watchDom4 = document.getElementById('tech-section-4')
      const height4 = watchDom4.getBoundingClientRect().top
      if (height4 < screenHeight / 2 && !this.p4Action) {
        const dom1 = document.getElementById('tech-section-4')
        if (isSmall) {
          dom1.classList.replace('monkey-hide', 'animate__fadeInDown')
        } else {
          dom1.classList.replace('monkey-hide', 'animate__backInRight')
        }
        this.p4Action = true
      }
    },
  },
}
</script>

<style lang="less" scope>
// 大屏样式
@media (min-width: 1001px) {
  .tech {
    // p1 css
    .tech-section-1 {
      width: 16.38rem;
      height: 16.23rem;
      background: url('../../assets/images/tech-p1_bg.png');
      background-repeat:no-repeat;
      background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      position: relative;
      z-index: 50;

      .sec-1-img-1 {
        width: .98rem;
        height: 1.16rem;
        padding-left: 1.51rem;
        padding-top: .15rem;
      }

      .sec-1-title {
        position: absolute;
        font-size: 1rem;
        color: #F0F2F4;
        top: 1.8rem;
        left: 2.88rem;
      }

      .sec-1-desc {
        font-size: .16rem;
        color: #C0C2C4;
        position: absolute;
        top: 3.53rem;
        left: 2.86rem;
        line-height: .4rem;

        .sec-1-desc-icon {
          padding-right: .2rem;
        }
      }
    }
    // p2 css
    .tech-section-2 {
      width: 16.2rem;
      height: 5.2rem;
      margin: -4.35rem auto 2.3rem auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      z-index: 100;

      .sec-2-item {
        width: 3rem;
        z-index: 101;

        .sec-2-item-img {
          width: 3rem;
          height: 4rem;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .sec-2-item-title {
          font-size: .28rem;
          color: #C0C2C4;
          text-align: right;
          padding-top: .46rem;

          .sec-2-item-title-icon {
            padding-right: .2rem;
          }
        }

        .sec-2-item-desc {
          font-size: .16rem;
          color: #006DA0;
          text-align: right;
          padding-top: .27rem;
        }
      }
    }
    // p3 css
    .tech-section-3 {
      position: relative;
      .sec-3-title {
        font-size: 1rem;
        color: #F0F2F4;
        padding-left: 2.88rem;
        line-height: 1.2rem;
      }

      .sec-3-desc {
        font-size: .16rem;
        color: #C0C2C4;
        padding-top: .8rem;
        padding-left: 2.88rem;
        padding-bottom: .9rem;
        line-height: .4rem;

        .sec-3-desc-icon {
          padding-right: .2rem;
        }
      }

      .sec-3-words {
        position: absolute;
        font-size: .16rem;
        color: #C0C2C4;

        .sec-3-words-title {
          font-family: 'SourceHanSansCNBold';

          .sec-3-words-title-icon {
            padding-right: .16rem;
          }
        }

        .sec-3-words-desc {
          padding-top: .3rem;
          padding-left: .28rem;
        }
      }

      .sec-3-words-1 {
        left: 7.5rem;
        top: 3.6rem;
      }

      .sec-3-words-2 {
        left: 12rem;
        top: 3.6rem;
      }

      .sec-3-img {
        width: 16.2rem;
        height: 7rem;
        margin: 0 auto 1.2rem auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

    }
    // p4 css
    .tech-section-4 {
      .sec-4-title {
        font-size: 1rem;
        color: #F0F2F4;
        padding-left: 2.88rem;
      }

      .sec-4-desc {
        font-size: .16rem;
        color: #C0C2C4;
        padding-top: .8rem;
        padding-left: 2.88rem;
        padding-bottom: .9rem;

        .sec-4-desc-icon {
          padding-right: .2rem;
        }
      }

      .sec-4-img {
        width: 16.2rem;
        height: 4.8rem;
        margin: 0 auto 1.2rem auto;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .tech_en {
    .tech-section-1 {
      .sec-1-desc {
        top: 4.5rem!important;
      }
    }

    .tech-section-3 {
      .sec-3-title {
        padding-right: .5rem;
      }

      .sec-3-desc {
        padding-right: .5rem;
      }

      .sec-3-words {
        top: 4.2rem!important;

        .sec-3-words-desc {
          width: 4rem!important;
          line-height: .3rem!important;
        }
      }
    }

    .tech-section-4 {
      .sec-4-desc {
        line-height: .5rem!important;
      }
    }
  }
}
// 小屏样式
@media (max-width: 1000px) {
  .tech {
    // p1 css
    .tech-section-1 {
      width: 7.4rem;
      height: 9.87rem;
      background: url('../../assets/images/phone-tech-p1_bg.png');
      background-repeat:no-repeat;
      background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      position: relative;
      z-index: 50;

      .sec-1-img-1 {
        width: .98rem;
        height: 1.16rem;
        padding-left: .39rem;
        padding-top: .11rem;
      }

      .sec-1-title {
        font-family: 'SourceHanSansCNBold';
        font-size: .72rem;
        color: #F0F2F4;
        position: absolute;
        top: 1.74rem;
        left: .4rem;
      }

      .sec-1-desc {
        font-size: .22rem;
        color: #C0C2C4;
        padding-left: .4rem;
        padding-right: .35rem;
        line-height: .48rem;
        top: 6.73rem;
        position: absolute;

        .sec-1-desc-icon {
          padding-right: .2rem;
        }
      }
    }
    // p2 css
    .tech-section-2 {
      z-index: 100;
      margin-top: -1.24rem;
      padding-left: .4rem;

      .sec-2-item {
        z-index: 150;
        position: relative;
        margin-bottom: .4rem;

        .sec-2-item-img {
          width: 3rem;
          height: 4rem;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .sec-2-item-title {
          position: absolute;
          top: 1.45rem;
          right: 1.16rem;
          text-align: right;
          font-size: .28rem;
          color: #C0C2C4;
          font-family: 'SourceHanSansCNBold';

          .sec-2-item-title-icon {
            padding-right: .2rem;
          }
        }

        .sec-2-item-desc {
          position: absolute;
          right: 1.16rem;
          text-align: right;
          top: 1.96rem;
          font-size: .22rem;
          color: #006DA0;
        }
      }
    }
    // p3 css
    .tech-section-3 {
      padding-top: 1.5rem;
      .sec-3-title {
        font-family: 'SourceHanSansCNBold';
        font-size: .72rem;
        color: #F0F2F4;
        padding-left: .4rem;
      }

      .sec-3-desc {
        font-size: .22rem;
        color: #C0C2C4;
        padding-top: .8rem;
        padding-left: .4rem;
        padding-right: 1.5rem;
        line-height: .48rem;

        .sec-3-desc-icon {
          padding-right: .2rem;
        }
      }

      .sec-3-words {
        padding-left: .4rem;
        font-size: .16rem;
        color: #C0C2C4;
        padding-top: .8rem;

        .sec-3-words-title {
          font-family: 'SourceHanSansCNBold';

          .sec-3-words-title-icon {
            padding-right: .16rem;
          }
        }

        .sec-3-words-desc {
          padding-top: .3rem;
          padding-left: .28rem;
        }
      }

      .sec-3-img {
        width: 7.5rem;
        height: auto;
        margin: 0 auto 1.2rem auto;

        img {
          width: 100%;
          height: auto;
        }
      }

    }
    // p4 css
    .tech-section-4 {
      .sec-4-title {
        font-size: .72rem;
        color: #F0F2F4;
        padding-left: .4rem;
      }

      .sec-4-desc {
        font-size: .22rem;
        color: #C0C2C4;
        padding-top: .8rem;
        padding-left: .4rem;
        padding-right: 1.5rem;
        line-height: .48rem;

        .sec-4-desc-icon {
          padding-right: .2rem;
        }
      }

      .sec-4-img {
        width: 6.7rem;
        height: 1.98rem;
        margin: .6rem auto .78rem auto;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>